@use 'common'

.wrapper
	text-align: center
	margin: 2.5rem 0

.title
	font-weight: 600
	margin-top: 1rem
	font-size: 1.8rem
	color: common.$color-black
	font-family: common.$albra-semi-bold-font
