@use 'common'

.root
	text-align: left
	padding-bottom: 1rem
	&Vertical
		border: 1px solid common.$color-grey-3
		border-radius: common.$radius-small
		padding-top: 0.5rem
		background-color: common.$color-white
		.label
			position: relative
			border: 0
			text-align: left
			font-size: 1.1rem
			color: common.$color-grey-4
			display: flex
			align-items: center
			&::before
				width: 15px
				height: 15px
				content: ''
				display: block
				opacity: 1
				position: relative
				transition: 0.1s ease-out opacity
				border-radius: 2px
				margin-right: 0.5rem
				background-color: transparent
				filter: none
				border: 1px solid common.$color-grey-4
				z-index: 1
		.labelChecked
			color: common.$color-violet-4
			.icon
				position: absolute
				top: 6px
				color: common.$color-violet-4

.label
	margin: 0.5rem
	display: inline-block
	user-select: none
	border: 1px solid
	border-radius: 5px
	padding: 6px 1rem
	font-size: 1rem
	color: common.$color-grey-4
	position: relative
	text-align: center
	transition: 0.2s ease-out border-color, 0.2s ease-out color, 0.1s background-color ease-in
	user-select: none
	&::first-letter
		text-transform: uppercase
	&::selection
		background: none
	@media (hover: hover)
		&:hover
			border-color: common.$color-violet-4
			color: common.$color-violet-4
	&::before
		width: 100%
		height: 100%
		content: ''
		display: block
		position: absolute
		top: 0
		left: 0
		transition: 0.1s ease-out opacity
		background-color: common.$color-violet-2
		filter: brightness(2.8)
		opacity: 0
		z-index: 1
	&Checked
		color: common.$color-violet-4
		border: 1px solid

	&Disabled
		border-color: common.$color-grey-2
		background-color: common.$color-grey-2
		cursor: default
		&:hover
			border-color: common.$color-grey-2
			color: common.$color-grey-6

.icon
	display: inline-block
	margin-right: 0.5rem
	width: 12px
	height: 8px

.checkbox
	opacity: 0
	pointer-events: none
	position: absolute
	z-index: 1
	top: 0
	left: 0

.button
	position: relative
	background-color: transparent
	display: flex
	gap: 0.2rem
	color: common.$color-grey-5
	font-size: 1rem
	font-family: common.$inter-font
	padding: 0
	margin: 1rem 0.5rem

	transition: 0.2s ease-out color
	&::after
		width: 100%
		content: ''
		height: 1px
		top: 100%
		left: 0
		position: absolute
		display: block
		opacity: 0
		transition: 0.2s ease-out opacity
		background-color: common.$color-grey-4
	&:hover
		&::after
			opacity: 1

	&:focus
		outline: none
	&Wrapper
		&Left
			margin-left: 1rem

.buttonIcon
	margin-top: 2px
