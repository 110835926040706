@use 'common'

.wrapper
	display: inline-flex
	position: relative
	z-index: 0

	&.is_rainbowWave
		.highlight
			width: 103%

	&.is_pencilCircle,
	&.is_pencilCircle2
		.highlight
			top: 50%
			left: 50%
			width: 150%
			transform: translate(-50%, -50%)
			margin-top: -0.3125rem
			font-size: 1.2em
			margin-top: .5rem

	&.is_pencilUnderline
		.highlight
			width: 110%

	&.is_rainbowWave,
	&.is_pencilUnderline,
	&.is_dashedArrow
		.highlight
			left: 50%
			transform: translateX(-50%)
			bottom: -0.625em

	&.is_rainbowWave,
	&.is_pencilUnderline
		.highlight
			font-size: calc(1em / 3.5)

	&.is_dashedArrow
		.highlight
			width: 100%
			font-size: calc(1em / 2)

	&.is_arrowUp
		.highlight
			bottom: 0
			right: -0.2em

	&.is_arrowCycleBelowReverse
		.highlight
			bottom: -0.5em

	&.is_arrowLifeCycle
		.highlight
			width: 85%
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			font-size: calc(1.5em)
			margin-top: .3125rem

.content
	white-space: nowrap
	position: relative
	z-index: 0

.highlight
	display: flex
	position: absolute
	color: var(--HighlightText-fillColor, var(--color-primary))

	svg
		width: 100%
