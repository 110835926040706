@use 'common'

.wrapper
	background-color: common.$color-grey-1
	border-radius: common.$radius-small
	padding: 1.5rem 2rem
	margin: 2rem 0
	gap: 1rem
	color: common.$color-grey-5
	display: grid
	@media (min-width: common.$break37)
		grid-template-columns: 1fr 2fr

.imageWrapper
	border-radius: 50%
	max-width: 100px
	height: 100px
	position: relative
	overflow: hidden
	margin-bottom: 1rem

.image
	object-fit: cover

.name
	font-size: 1rem
	color: common.$color-black
	font-weight: 600
	@media (min-width: common.$break48)
		font-size: 1.2rem

.role
	font-size: 0.9rem

.text
	font-size: 1rem
	@media (min-width: common.$break48)
		font-size: 1.1rem
