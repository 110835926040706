@use 'common'

.wrapper
	display: flex
	z-index: 1
	padding: 0.5rem 0
	gap: 0.75rem
	flex-direction: column
	@media (min-width: common.$break48)
		padding: 2rem 0
		flex-direction: row
		gap: 0.5rem
		align-items: center

.title
	height: fit-content
	@media (min-width: common.$break48)
		transform: rotate(-90deg)

.iconBg
	background-color: common.$color-grey-1
	border-radius: 50%
	width: 50px
	height: 50px
	display: flex
	align-items: center
	transition: background-color 0.2s ease-in-out
	justify-content: center

	&:hover
		background-color: common.$color-grey-2

.iconWrapper
	width: 26px
	height: 26px
	font-size: 23px

.socials
	display: flex
	align-items: center
	gap: 1rem
	@media (min-width: common.$break48)
		flex-direction: column
		justify-content: center

.iconButton
	&:focus
		outline: none

.printWrapper
	@media (min-width: common.$break48)
		align-self: flex-end
		margin-left: 0.5rem
