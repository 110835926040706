@use 'common'

$-break: common.$break75

.headWrapper
	--Header-maxWidth: #{common.$break128}
	--Header-inlineSpacing: var(--Header-wrapper-inlineSpacing)
	@media (min-width: common.$break48)
		padding-top: 0.5rem

.header
	background-color: common.$color-white
	box-shadow: 0px 20px 60px 20px #00000005

.bottomBorder
	display: none

	@media (min-width: common.$break48)
		display: block
		width: 100%
		width: calc(100% - 2 * var(--Header-inlineSpacing))
		max-width: calc(var(--Header-maxWidth) - 2 * var(--Header-inlineSpacing))
		height: 1px
		background-color: common.$color-grey-2
		margin-inline: auto

.wrapper
	background-color: var(--background-color)
	padding-block: 1.2rem var(--Header-wrapper-bottomSpacing)
	margin-inline: calc(-0.9rem)

	&::before
		content: ''
		display: block
		position: absolute
		z-index: -1
		width: 100%
		height: 100%
		top: 0
		left: 0
		background-color: common.$color-white

	&::after
		content: ''
		display: block

	&.no_padding_top
		padding-top: 0

.in
	text-align: left
	column-gap: 1rem

	&::before
		content: ""
		display: block
		position: absolute
		bottom: 0
		background-color: common.$color-violet-4
		height: .0625rem
		width: var(--Header-activeLink-width)
		left: var(--Header-activeLink-offsetLeft, 50%)
		transform: translateX(-50%)
		transition-property: left, width, visibility, opacity
		transition-duration: 0.3s
		transition-timing-function: ease-in-out
		visibility: hidden
		opacity: 0

	.is_lineVisible &::before
		visibility: visible
		opacity: 1

.logo
	display: flex
	width: 100%
	height: 4rem
	&Link
		display: flex

.title
	margin: 0

.links
	list-style-type: none
	display: flex
	flex-wrap: wrap
	justify-content: left
	margin: 0 auto
	font-size: 1rem
	padding: unset

.item

.link
	display: inline-block
	padding: var(--Header-link-blockSpacing) 1rem
	text-decoration: none
	color: common.$color-grey-6
	transition-property: color
	transition-duration: 0.3s

	&Active
		color: common.$color-violet-4

	&:hover
		color: common.$color-violet-4

.linkIn
	display: flex
	align-items: center
	gap: .4375rem

.chevron
	display: flex
	font-size: 1.25rem
	transform: rotate(90deg)
	transition: transform 0.3s
	color: common.$color-primary

.button
	font-size: 1.125rem

	@media not all and (min-width: common.$break48)
		display: none

.sandwich
	font-size: 2.875rem
	background-color: transparent
	outline: none
	border-style: none

	@media (min-width: common.$break48)
		display: none

.head
	width: 100%

.siteWrapper
	display: flex
	padding-left: 1.5rem
	align-items: center

.iconWrapper
	width: 1.5rem
	height: 1.5rem
