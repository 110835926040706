@use 'common'

.wrapper
	//

.form
	display: flex
	flex-direction: column
	gap: 1rem
	@media (min-width: common.$break48)
		flex-direction: row
		gap: 2.5rem
		align-items: center

.inputWrapper
	//

.submitNote
	font-size: 1rem
	margin-top: 0.5rem
