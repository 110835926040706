@use 'common'

.wrapper
    display: block
    width: 100%
    margin-top: 1rem

.imageWrapper
    position: relative
    width: 100%
    aspect-ratio: 4/3
    border-radius: 10px
    overflow: hidden
    @media (min-width: common.$break48)
        aspect-ratio: 9/16
.image
    object-fit: cover

.imageWrapperMobileOnly
    @media (min-width: common.$break48)
        display: none

.imageWrapperDesktopOnly
    display: none
    @media (min-width: common.$break48)
        display: block
