@use 'common'

$imageSize: 112px
$fileWidth: 410px

.wrapper
	display: grid
	max-width: $fileWidth
	grid-template-columns: auto 2fr
	gap: 1rem
	&:hover
		.fileButtonTitle
			&::after
				top: 105%

.imageWrapper
	position: relative
	overflow: hidden
	width: $imageSize
	height: $imageSize
	border-radius: common.$radius-medium

.image
	object-fit: cover

.buttonItem
	display: inline-block
	margin-right: 0.5rem
	margin-top: 0.3rem
	font-size: 1rem

.file
	&Title
		margin-top: 0.7rem
		font-family: common.$albra-semi-bold-font
		font-size: 1.4rem
		max-width: 200px
	&Content
		color: common.$color-violet-3
		@media (min-width: common.$break37)
			display: flex
			align-items: center
	&Button
		border: 0
		background-color: transparent
		color: common.$color-violet-4
		padding: 0
		font-size: 1.1rem
		display: flex
		&Title
			position: relative
			&::after
				content: ''
				display: block
				width: 100%
				height: 1px
				transition: top 0.2s ease-in
				position: absolute
				top: 100%
				left: 0
				background-color: common.$color-violet-4

	&Icon
		width: 16px
		height: 20px
		margin-right: 0.5rem
	&Type
		text-transform: uppercase
		font-size: 1rem

.title
	font-size: 1.2rem
	font-family: common.$inter-font

.info
	//
