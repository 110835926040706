@use 'common'

.wrapper
	display: flex
	flex-direction: column
	height: 100%

.content
	display: grid
	gap: 1rem
	row-gap: 2rem

	@media (min-width: common.$break37)
		grid-template-columns: repeat(auto-fill, minmax(225px, 1fr))
		grid-row: 2

.buttonWrapper
	//
