@use 'common'

$eventSize: 205px
$videoSize: 300px
$webinarSize: 380px

.wrapper
	&Webinar
		margin-top: 2rem
		@media (min-width: common.$break48)
			margin-top: 4rem

.list
	list-style-type: none
	padding-left: 0
	&Events
		display: grid
		grid-template-columns: repeat(auto-fill, minmax($eventSize, 1fr))
		margin-bottom: 3rem
		gap: 1rem

.title
	font-size: 1.5rem
	margin-bottom: 1rem
	margin-top: 0.4rem
	@media (min-width: common.$break48)
		font-size: 1.8rem
		margin-bottom: 3rem
		margin-top: 70px

	&::first-letter
		text-transform: uppercase
	&Events
		text-align: center

.listTv
	display: grid
	grid-template-columns: repeat(auto-fill, minmax($videoSize, 1fr))
	grid-gap: 2rem
	margin-bottom: 3rem

.group
	&Content
		display: grid
		grid-template-columns: repeat(auto-fill, minmax($webinarSize, 1fr))
		grid-gap: 2rem
		z-index: 0
		position: relative
		margin-bottom: 4rem

.webinarTitle
	font-size: 1.7rem
	@media (min-width: common.$break48)
		font-size: 2rem

.noWebinars
	margin-bottom: 3rem
	margin-top: 3rem

.buttonWrapper
	text-align: center
	margin-top: 3rem
	@media (min-width: common.$break48)
		margin-top: 5rem
