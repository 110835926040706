@use 'common'

.wrapper
	width: 100%
	height: 100%
	display: flex
	flex-direction: column
	&Horizontal
		max-width: unset

.content
	display: flex
	flex-direction: column
	row-gap: 2rem
	&Horizontal
		flex-direction: row
		gap: 2rem
	&Wide
		display: grid
		gap: 0 1rem
		grid-template-columns: repeat(auto-fill, minmax(330px, 1fr))
