@use 'common'

.wrapper
	//

.buttonWrapper
	text-align: center
	margin-top: 3rem
	@media (min-width: common.$break48)
		margin-top: 5rem

.title
	font-size: 2.8rem
	@media (min-width: common.$break48)
		font-size: 3.4rem
