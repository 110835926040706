@use 'common'

.wrapper
	//

.main
	display: flex
	flex-direction: column
	align-items: center
	gap: 1.5rem

	@media (min-width: common.$break48)
		gap: 2rem

.image
	width: 100%
	height: auto

	&Wrapper
		display: flex
		position: relative
		max-width: 450px
		width: 100%
		max-height: 280px
		overflow: hidden
		border-radius: common.$radius-medium

.title
	margin-block: unset
	text-align: center

.description
	margin-block: unset
	text-align: center
	margin-top: -1rem
	margin-bottom: 1rem

.form
	display: flex
	align-items: center
	gap: 1rem
	flex-direction: column
	width: 100%

	@media not all and (min-width: common.$break62)
		justify-content: center

	@media (min-width: common.$break48)
		flex-direction: row
		width: auto
		gap: 2.5rem

.inputWrapper
	position: relative
	width: 80%
	@media (min-width: common.$break48)
		width: auto

.inputSubscribed
	font-size: 0.9rem
	position: absolute
	top: calc(100% + 5px)
	left: 0
