@use 'common'

$borderWidth: 5px
$smallBorderWidth: 3px

.loader
	display: inline-flex
	margin-left: auto
	margin-right: auto
	border: $borderWidth solid common.$color-grey-3
	border-top: $borderWidth solid common.$color-violet-5
	border-radius: 50%
	width: 60px - $borderWidth
	height: 60px - $borderWidth
	animation: spin 2s linear infinite

	@keyframes spin
		0%
			transform: rotate(0deg)
		100%
			transform: rotate(360deg)
	&Small
		width: 20px - $smallBorderWidth
		height: 20px - $smallBorderWidth
		border: $smallBorderWidth solid common.$color-grey-3
		border-top: $smallBorderWidth solid common.$color-violet-5
		margin-left: 0.5rem
