@use 'common'

.heading
	font-size: 1.9rem
	text-align: center
	@media (min-width: common.$break48)
		font-size: 2.2rem

.labelGroup
	position: relative
	width: fit-content
	overflow: hidden
	&After
		&::after
			position: absolute
			top: -0.3rem
			right: -40px
			z-index: 1
			background-color: common.$color-white
			content: ''
			width: 50px
			height: 100%
			display: none
			-webkit-box-shadow: -50px 0px 52px 43px rgba(255,255,255,1)
			-moz-box-shadow: -50px 0px 52px 43px rgba(255,255,255,1)
			box-shadow: -50px 0px 52px 43px rgba(255,255,255,1)
		@media (min-width: common.$break62)
			&::after
				display: block

.chevron
	display: none
	@media (min-width: common.$break62)
		display: block
		position: absolute
		top: 1rem
		right: 0
		z-index: 2
		cursor: pointer

.filter
	width: 100%
	display: flex
	justify-content: space-between
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row
		gap: 3rem

.selectWrapper
	position: relative
	max-width: 400px
	@media (min-width: common.$break48)
		max-width: unset
