@use 'common'

$paddingTop: 1.8rem
$paddingInline: 1.5rem
$infoMargin: 0.7rem
$lineMaxWidth: 70%

.info
	//

.title
	font-size: 2rem
	margin-bottom: 1.5rem
	@media (min-width: common.$break48)
		font-size: 3rem

.contentIn
	display: grid
	gap: 2.8rem
	margin-top: 1rem
	@media (min-width: common.$break48)
		grid-template-columns: 2.3fr 1fr
		margin-top: 2rem
	&WithoutRecommend
		grid-template-columns: 1fr

.imageWrapper
	position: relative
	overflow: hidden
	width: 100%
	aspect-ratio: 27/18
	border-radius: common.$radius-medium

.image
	object-fit: cover

.meta
	margin-bottom: 1.5rem
	font-size: 1rem
	&Embed
		text-align: left
		margin-top: 1rem
	@media (min-width: common.$break48)
		font-size: 1.125rem

.related
	&Title
		font-family: common.$inter-font
		font-size: 1.1rem
		color: common.$color-grey-4
	&Wrapper
		height: fit-content
		margin-top: 0.6rem
		padding-top: 0.6rem
		position: sticky
		top: 0
		display: flex
		flex-direction: column

	&ArticlesWrapper
		//

.meta
	&Item
		display: block
		@media (min-width: common.$break37)
			display: inline-block
			margin-inline: 1rem
		&:first-of-type
			margin-left: 0

.noLeftMargin
	margin-left: 0

.restArticlesWrapper
	margin-top: 4rem
	margin-bottom: 2rem

.lead
	font-size: 1rem
	color: common.$color-black
	font-weight: 600
	margin-bottom: 1rem
	@media (min-width: common.$break48)
		font-size: 1.13rem
		margin-bottom: 2rem

.buttonWrapper
	text-align: center

.printButton
	position: absolute
	top: 0
	right: 0

.contentWrapper
	position: relative

.printWrapper
	position: relative

.content
	display: flex
	position: relative
	margin-bottom: 4rem

.relatedArticlesWrapper
	display: flex
	flex-direction: column
	@media (min-width: common.$break48)
		height: 100%
	&ShareDown
		@media (min-width: common.$break48)
			justify-content: flex-end

.in
	display: flex
	flex-direction: column
	@media (min-width: common.$break48)
		height: 100%
		justify-content: space-between

.spline
	display: none
	@media (min-width: common.$break48)
		display: block
	div
		border-radius: common.$radius-medium

.splineImage
	position: relative
	overflow: hidden
	width: 100%
	aspect-ratio: 27/18
	border-radius: common.$radius-medium
	@media (min-width: common.$break48)
		display: none

.actualized
	display: flex
	justify-content: space-between

.textToSpeechSection
	margin-top: 2rem
.audio
	h4
		font-size: 1.1rem
		margin-bottom: 0.6rem
		@media (min-width: common.$break48)
			font-size: 1.5rem
	audio
		max-width: 600px
		width: 100%

	audio::-webkit-media-controls-panel
		background-color: common.$color-grey-1
		margin: 0.7rem
		width: 100%
		padding: 0

	audio::-webkit-media-controls-play-button
		background-color: common.$color-violet-4
		position: relative
		border-radius: 50%
		display: block
