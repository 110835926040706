@use 'common'

$paddingTop: 1.8rem
$paddingInline: 1.5rem
$infoMargin: 0.7rem
$lineMaxWidth: 70%

.info
	padding-right: 10%

.wrapper
	display: grid
	grid-template-columns: 1fr
	gap: 3rem
	@media (min-width: common.$break64)
		grid-template-columns: 1fr 1fr

.title
	font-size: 2rem
	@media (min-width: common.$break48)
		font-size: 2.5rem

.imageWrapper
	position: relative
	border-radius: common.$radius-medium
	width: 100%
	overflow: hidden
	height: 250px
	padding-right: 2rem
	max-width: 570px
	@media (min-width: common.$break48)
		height: 490px

.image
	object-fit: cover

.label
	border: 1px solid common.$color-grey-3
	border-radius: 4px
	padding: 0.5rem 0.8rem
	color: common.$color-grey-4
	margin: 1rem 0.5rem 0 0
	font-size: 1rem
	user-select: none
	display: inline-block
	&Group
		margin-top: $infoMargin
		&::before
			max-width: 100%
			width: 100%
			height: 1px
			background-color: common.$color-grey-2
			display: block
			content: ''
		@media (min-width: common.$break48)
			max-width: $lineMaxWidth

.dateAndPlace
	position: relative
	font-weight: 600
	cursor: pointer
	color: common.$color-grey-4
	margin-bottom: $infoMargin
	transition: 0.2s ease-in-out color
	&::before
		max-width: 100%
		width: 100%
		height: 1px
		background-color: common.$color-grey-2
		display: block
		content: ''
		margin-bottom: $infoMargin
		@media (min-width: common.$break48)
			max-width: $lineMaxWidth

	&:hover
		color: common.$color-violet-3
	&Only
		&:hover
			color: common.$color-grey-4
			cursor: default

.place
	position: relative
	font-weight: 600
	color: common.$color-grey-4
	margin-bottom: $infoMargin
	&::before
		max-width: 100%
		width: 100%
		height: 1px
		background-color: common.$color-grey-2
		display: block
		content: ''
		margin-bottom: $infoMargin
		@media (min-width: common.$break48)
			max-width: $lineMaxWidth

.buttonWrapper
	margin-top: 2rem

.selected
	position: absolute
	top: 1rem
	left: 0.3rem
	width: 1rem
	height: 1rem
	background-color: common.$color-violet-4
	border-radius: 100%

.date
	padding-left: 2rem
	&Selected
		color: common.$color-violet-4

.contentWrapper
	margin-top: 5rem
