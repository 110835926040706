@use 'common'

.wrapper
    position: relative
    z-index: 0

.gradient
    &::after
        content: ''
        position: absolute
        right: 0
        bottom: 100%
        left: 0
        height: 8.125rem
        background: linear-gradient(to bottom, transparent 0%, common.$color-white 100%)

.content
    display: flex
    align-items: center
    flex-direction: column
    gap: 0.2rem
    margin-top: 2.5rem
    text-align: center

.appBanner
    //

.title
    max-width: 250px
    font-weight: 600
    font-size: 1.2rem
    color: common.$color-black
    margin-bottom: 0.5rem

.lock
    position: relative
    width: 40px

.form
    &In
        border: 0
    &Title
        font-size: 1.4rem
        font-weight: 600
        color: common.$color-black
        margin: 1rem 0

.buttonWrapper
    margin: 2rem 0 1rem
    &:last-of-type
        margin: 0
