@use 'common'

.wrapper
	display: flex
	justify-content: center
	flex-direction: column

.image
	width: 100%

	&Wrapper
		max-width: calc(var(--ImageBlock-image-width) * 1px)
		width: 100%

.alt
	font-size: 0.9rem
	margin-top: 0.5rem
	margin-left: 2px
	color: common.$color-grey-4
