@use 'common'

.wrapper
	--EventBox-media-size: 5.625rem
	display: flex

	@media (min-width: common.$break26)
		--EventBox-media-size: 112px

	@media (min-width: common.$break37)
		justify-content: center

.event
	display: flex
	flex-direction: row
	gap: 1.5rem

	@media (min-width: common.$break37)
		flex-direction: column
		margin-inline: auto
		align-items: center

	@media (min-width: common.$break48)
		margin-inline: 0

	&Content
		@media (min-width: common.$break37)
			text-align: center
			max-width: 12.8125rem

	&Link
		display: flex

	&Icon
		width: var(--EventBox-media-size)
		flex-shrink: 0
		aspect-ratio: 1
		text-align: center
		transition: filter 0.2s ease-in
		&Link
			&:hover
				filter: brightness(.92)
	&Day
		color: common.$color-black
		background-color: common.$color-grey-1
		border-radius: common.$radius-medium common.$radius-medium 0 0
		font-family: common.$albra-semi-bold-font
		font-size: 2.1rem
		font-weight: 600
		padding: 0.8rem 1.1rem 0.4rem 1.1rem

	&Month
		background-color: var(--bg-color)
		color: common.$color-black
		font-weight: 600
		padding: 0.6rem 0.4rem 0.6rem 0.4rem
		border-radius: 0 0 common.$radius-medium common.$radius-medium
		&::first-letter
			text-transform: uppercase
	&Title
		color: common.$color-black
		font-size: 1.3rem
		font-weight: 600
		line-height: 1.2
	&Location
		color: common.$color-grey-4
		font-size: 1rem
		margin-top: 0.2rem

.imageWrapper
	position: relative
	overflow: hidden
	width: var(--EventBox-media-size)
	aspect-ratio: 1
	border-radius: common.$radius-medium

.image
	object-fit: cover
