@use 'common'

.overlay
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    inset: 0
    background-color: rgba(0, 0, 0, 0.3)
    padding: var(--global-horizontal-spacing)
    z-index: 10
    opacity: 0
    overflow-y: inherit
    visibility: hidden
    transition-property: visibility, opacity
    transition-duration: .3s

    &.is_afterOpen
        opacity: 1
        visibility: visible

    &.is_beforeClose
        opacity: 0
        visibility: hidden

    @media (min-width: common.$break48)
        padding: 3.125rem

    &Hidden
        position: absolute
        top: -9999px
        left: -9999px

.modal
    position: fixed
    display: flex
    z-index: 1
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow: auto
    background-color: rgba(0,0,0,0.3)
    @media (min-width: common.$break48)
        display: block

.main
    text-align: center
    display: flex
    flex-direction: column
