@use 'common'

$bannerSize: 270px

.wrapper
	gap: 1rem
	max-width: $bannerSize
	margin-bottom: 2rem
	text-decoration: none
	&:hover
		.title
			color: common.$color-grey-5

.title
	font-size: 1.2rem
	text-decoration: none
	transition: 0.2s ease-in color
	font-family: common.$albra-semi-bold-font
	margin-bottom: 0.7rem

.description
	display: flex
	gap: 1.2rem
	font-size: 0.9rem
	text-decoration: none
	color: common.$color-grey-4
