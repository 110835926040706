@use 'common'

$paddingTop: 1.8rem
$paddingInline: 1.5rem

.wrapper
	position: relative
	overflow: hidden
	border-radius: common.$radius-medium
	min-height: 300px
	max-width: 500px
	@media (min-width: common.$break48)
		margin-bottom: 3rem

	&Filtered
		filter: grayscale(100%)

.image
	position: absolute
	&Wrapper
		&::after
			content: ''
			background-color: rgba(0, 0, 0, 0.4)
			width: 100%
			height: 100%
			z-index: 1
			position: absolute
			display: block

.content
	z-index: 1
	display: flex
	flex-direction: column
	position: relative
	padding: $paddingTop $paddingInline
	&Title
		font-size: 1.1rem
		color: common.$color-white
		line-height: 1.3
		font-family: common.$inter-font
		max-width: 80%
	&Top
		display: grid
		grid-template-columns: 2fr 1fr
		gap: 1rem
		&FullTitle
			display: block

.link
	text-decoration: unset

.buttonWrapper
	position: absolute
	top: $paddingTop
	right: $paddingInline
	z-index: 1

.background
	background-color: common.$color-violet-4

.date
	color: common.$color-grey-2
	font-size: 0.9rem

.labelGroup
	margin-top: 1rem

.label
	font-size: 0.9rem
	color: common.$color-grey-2
	margin-right: 0.7rem
