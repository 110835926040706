@use 'common'

$bannerSize: 400px
$bannerHeight: 700px

.wrapper
	display: block
	width: 100%
	overflow: hidden
	margin-top: 4rem
	@media (min-width: common.$break75)
		margin-top: 0

.imageWrapper
	position: relative
	width: 100%
	aspect-ratio: 2/1
	border-radius: 10px
	overflow: hidden
	@media (min-width: common.$break75)
		aspect-ratio: 2/3

.image
	object-fit: cover

.imageWrapperMobile
	@media (min-width: common.$break75)
		display: none

.imageWrapperDesktop
	display: none
	@media (min-width: common.$break75)
		display: block

// Carousel styles
.carouselWrapper
	position: relative
	width: 100%
	margin-top: 2rem

.slidesContainer
	position: relative
	width: 100%
	overflow: hidden
	height: 220px
	aspect-ratio: 2/1
	@media (min-width: common.$break26)
		height: 260px
	@media (min-width: common.$break30)
		height: 320px
	@media (min-width: common.$break37)
		height: 340px
	@media (min-width: common.$break48)
		min-height: 440px
	@media (min-width: common.$break62)
		min-height: 540px
	@media (min-width: common.$break64)
		min-height: 620px
	@media (min-width: common.$break75)
		min-height: 590px
		aspect-ratio: 2/3

.slide
	position: absolute
	width: 100%
	transition: transform 0.5s ease-in-out
	&.active
		transform: translateX(0)
	&.prev
		transform: translateX(-100%)
	&.next
		transform: translateX(100%)

.prevButton,
.nextButton
	position: absolute
	top: 50%
	transform: translateY(-50%)
	z-index: 2
	background: transparent
	color: white
	border: none
	border-radius: 50%
	display: flex
	align-items: center
	justify-content: center
	width: 42px
	height: 42px
	cursor: pointer
	transition: background 0.3s
	&:hover
		background: rgba(0, 0, 0, 0.1)

.iconLeft
	margin-right: 2px

.iconRight
	margin-left: 2px

.prevButton
	left: 10px

.nextButton
	right: 10px

.dots
	position: absolute
	bottom: 20px
	left: 50%
	transform: translateX(-50%)
	display: flex
	gap: 8px
	z-index: 2

.dot
	width: 8px
	height: 8px
	border-radius: 50%
	background: rgba(255, 255, 255, 0.5)
	border: none
	cursor: pointer
	padding: 0
	&.activeDot
		background: white

.borderRadius
	border-radius: 10px

.link
	transition: box-shadow 0.3s ease
	&:hover
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4)
