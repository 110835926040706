@use 'common'

.wrapper
	position: relative
	width: 100%

.select
	background-color: common.$color-grey-1
	border-radius: common.$radius-small
	padding: 0.8rem 1rem
	margin-bottom: 0.4rem
	display: flex
	cursor: pointer
	font-size: 1.1rem
	align-items: center
	min-width: common.$tileWidth
	justify-content: space-between

.separatedByComma
	&:not(:first-child)
		&::before
			content: ','
			margin-right: 5px
			display: inline-block

.icon
	z-index: 1
	position: relative
	color: common.$color-violet-5
	transition: transform 0.3s ease-in
	width: 1em
	height: 1em
	margin-left: 0.6rem
	margin-bottom: 1px
	&Rotate
		transform: scale(-1)
	&In
		transform: rotate(90deg)
		display: grid

.list
	transform: translateY(10px)
	transition-property: visibility, opacity, transform
	transition-duration: 0.3s
	transition-timing-function: ease-in-out
	visibility: hidden
	opacity: 0
	position: absolute
	top: calc(100% + 1rem)
	left: 0
	z-index: 1
	width: 100%
	&IsVisible
		visibility: visible
		opacity: 1
		transform: translateY(0)
