@use 'common'

.wrapper
	display: grid
	grid-template-columns: repeat(auto-fill, minmax(common.$tileWidth, 1fr))
	grid-gap: 3rem
	margin-top: 3rem
	@media (min-width: common.$break48)
		gap: 3.25rem 2.1rem
		margin-top: 4rem
