@use 'common'

.wrapper
	margin-bottom: 3rem

.item
	display: grid
	gap: 1.25rem
	row-gap: 1rem

	@media (min-width: common.$break37)
		row-gap: 4rem
		gap: 2.5rem
		grid-template-columns: 1fr 1fr

.image
	object-fit: cover

	&Wrapper
		position: relative
		transition: filter .3s ease-in-out
		aspect-ratio: 27/18
		width: 100%
		z-index: 0
		border-radius: common.$radius-medium
		overflow: hidden


.content
	display: flex
	flex-direction: column

	&Top
		+common.body2

		display: flex
		flex-wrap: wrap
		gap: 2.5rem
		row-gap: .5rem
		margin-bottom: .5rem
		color: common.$color-grey-4

	&Title
		margin-bottom: .5rem

		@media (min-width: common.$break48)
			margin-bottom: 1.5rem

	&Lead
		color: common.$color-grey-5
		margin-block: unset

		@media (min-width: common.$break37)
			display: -webkit-box
			-webkit-line-clamp: 6
			-webkit-box-orient: vertical
			overflow: hidden

		@media (min-width: common.$break48)
			-webkit-line-clamp: 4

		@media (min-width: common.$break62)
			-webkit-line-clamp: 5

		@media (min-width: common.$break64)
			-webkit-line-clamp: 7

		@media (min-width: common.$break75)
			-webkit-line-clamp: 10

		@media (min-width: common.$break92)
			-webkit-line-clamp: 12

.link
	text-decoration: unset
	&:hover, &:focus-visible
		.imageWrapper
			filter: brightness(85%)

.buttonWrapper
	text-align: center
