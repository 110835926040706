@use 'common'

$paddingTop: 1.8rem
$paddingInline: 1.5rem

.wrapper
	margin-bottom: 3rem
	position: relative
	overflow: hidden
	border-radius: common.$radius-medium
	height: 300px
	background-color: common.$color-grey-5
	opacity: 0.2
	flex-shrink: 0
	position: relative
	overflow: hidden
	&::after
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		transform: translateX(-100%)
		background-image: linear-gradient(90deg,rgba(#fff, 0) 0,rgba(#000, 0.05) 20%,rgba(#fff, 0.4) 60%,rgba(#fff, 0))
		animation: shimmer 2s infinite
		content: ''

		@keyframes shimmer
			100%
				transform: translateX(100%)
