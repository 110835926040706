@use 'common'

.wrapper
	position: relative
	min-height: 100vh
	display: flex
	background: common.$color-white
	flex-direction: column

	&:not(.is_site_global)

		&.is_careerPage
			.hero
				margin-bottom: 2.5rem

	@media (min-width: common.$break75)
		&:not(.is_homePage)
			padding-top: var(--Header-height)

.hero
	margin-bottom: var(--content-gap-default)

.content
	padding-top: 3.5rem
	padding-bottom: 5rem
	@media (min-width: common.$break48)
		padding-top: 6.785rem
		padding-bottom: 10rem

.footer
	//

.spacing
	padding-bottom: var(--content-gap-default)

	@media (min-width: common.$break75)
		&.is_homePage
			display: none

.smallMarginBottom
	margin-bottom: var(--content-smallSpacing)

.smallMarginTop
	padding-bottom: var(--content-smallSpacing)

.headerWrapper
	padding: 1.5rem

.minusPadding
	padding-top: 0

.secondaryFooter
	h1, h2, h3, h4, h5
		color: common.$color-white
