@use 'common'

.wrapper
	display: grid

.in
	aspect-ratio: 16 / 9
	width: 100%
	height: auto
	border: none
