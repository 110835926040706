@use 'common'

.header
	display: flex
	margin-bottom: 1.3rem

.title
	font-size: 1.5rem
	font-family: common.$inter-font
	margin-bottom: 0

.iconWrapper
	margin-right: 0.5rem
	> svg
		height: 1.7rem
