@use 'common'

.wrapper
	//

	&.is_subtitle1
		+common.subtitle1

	&.is_subtitle2
		+common.subtitle2
