@use 'common'

.wrapper
	margin-bottom: 4rem

.title
	margin-bottom: 1.5rem
	text-align: center

	@media (min-width: common.$break48)
		margin-bottom: 2rem

.item
	display: flex
	flex-direction: column
	gap: 0.75rem

.items
	display: grid
	gap: 2rem
	row-gap: 4rem
	grid-template-columns: 1fr

	@media (min-width: common.$break37)
		grid-template-columns: 1fr 1fr 1fr

	@media (min-width: common.$break62)
		grid-template-columns: repeat(12, 1fr)

		&.isFirstArticlesBigger
			.link:nth-child(-n+3)
				grid-column: span 4

.link
	grid-column: span 1
	text-decoration: unset

	&:hover, &:focus-visible
		.imageWrapper
			filter: brightness(85%)

	@media (min-width: common.$break62)
		grid-column: span 3

.image
	object-fit: cover

	&Wrapper
		position: relative
		transition: filter .3s ease-in-out
		aspect-ratio: 27/18
		width: 100%
		z-index: 0
		border-radius: common.$radius-medium
		overflow: hidden


.content
	display: flex
	flex-direction: column
	gap: .5rem

	&Top
		+common.body2

		display: flex
		flex-wrap: wrap
		gap: 2rem
		row-gap: .3rem
		line-height: 1
		color: common.$color-grey-4

	&Title
		margin: unset
		font-size: 1.4rem
		@media (min-width: common.$break48)
			font-size: 1.5rem
