@use 'common'

.wrapper
	border-radius: common.$radius-medium
	padding: 1.5rem 2rem
	margin: 2rem 0
	gap: 2rem
	color: common.$color-grey-5
	.tipBoxText a
		color: common.$color-white
		text-decoration: underline
		transition: color 0.2s ease-out
		&:hover
			text-decoration: none
			color: common.$color-white

.borderColor
	border: 1px solid var(--tip-box-color)

.hasBackground
	background-color: var(--tip-box-color)
	color: common.$color-white

.link
	transition: filter 0.2s ease-in-out
	&:hover
		filter: brightness(0.95)

.imageWrapper
	border-radius: common.$radius-small
	max-width: 100%
	height: 500px
	position: relative
	overflow: hidden
	margin-bottom: 1rem

.image
	object-fit: cover

	.tipBoxText
		.contentRenderer
		a[data-contember-type="anchor"]
			color: common.$color-white
			transition: color 0.2s ease-out
			text-decoration: underline
		p
			a[data-contember-type="anchor"]
				color: common.$color-white
				transition: color 0.2s ease-out
				text-decoration: underline
