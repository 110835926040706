@use 'common'

@keyframes rotate
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)

.container
	position: absolute
	transition: opacity ease-in-out .1s
	vertical-align: baseline
	display: inline-flex
	align-items: center
	height: 100%
	top: 0
	margin: .125em .5em
	opacity: .5

.spinner
	height: 1rem
	&::before
		width: 0.8rem
		height: 0.8rem
		flex-grow: 0
		flex-shrink: 0
		display: block
		content: ""
		border-radius: 2em
		border: solid .15em
		border-right-color: transparent
		animation: rotate 1s linear infinite
