@use 'common'

.wrapper
	max-width: 410px
	width: 100%
	height: 100%
	display: flex
	flex-direction: column
	&Horizontal
		max-width: unset

.content
	display: flex
	flex-direction: column
	row-gap: 31px
	&Horizontal
		display: grid
		@media (min-width: common.$break75)
			grid-template-columns: 1fr 1fr 1fr
			gap: 1.5rem
