@use 'common'

$tileImageSize: 112px
$bannerSize: 620px
.wrapper
	display: flex
	gap: 1.4rem
	max-width: $bannerSize
	margin-bottom: 1.5rem
	text-decoration: none
	border-radius: common.$radius-medium
	&:hover
		.imageWrapper
			filter: brightness(.9)
		.title
			color: common.$color-grey-5
	@media (min-width: common.$break48)
		margin-bottom: 3rem

.imageWrapper
	border-radius: common.$radius-medium
	width: $tileImageSize
	height: $tileImageSize
	position: relative
	overflow: hidden
	flex: 0 0 auto
	transition: filter 0.2s ease-in-out

.image
	object-fit: cover

.title
	font-size: 1rem
	text-decoration: none
	transition: color ease-in-out 0.2s
	font-family: common.$inter-font
	margin-bottom: 0.8rem
	@media (min-width: common.$break48)
		font-size: 1.2rem

.description
	display: flex
	font-size: 0.9rem
	text-decoration: none
	flex-direction: column
	color: common.$color-grey-4
	@media (min-width: common.$break48)
		font-size: 1rem
		flex-direction: row
		gap: 1.2rem
