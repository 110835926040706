@use 'common'

.wrapper
    display: block
    width: 100%
    overflow: hidden
    margin-bottom: 8rem

.borderRadius
    border-radius: 10px

.imageWrapper
    position: relative
    width: 100%
    aspect-ratio: 3
    overflow: hidden
    @media (min-width: common.$break48)
        aspect-ratio: 5

.image
    object-fit: cover

// Carousel styles
.carouselWrapper
    position: relative
    width: 100%
    margin: 2rem 0 6rem

.slidesContainer
    position: relative
    width: 100%
    overflow: hidden
    height: 150px
    @media (min-width: common.$break48)
        height: 240px

.slide
    position: absolute
    width: 100%
    transition: transform 0.5s ease-in-out
    &.active
        transform: translateX(0)
    &.prev
        transform: translateX(-100%)
    &.next
        transform: translateX(100%)

.prevButton,
.nextButton
    position: absolute
    top: 50%
    transform: translateY(-50%)
    z-index: 2
    background: transparent
    color: white
    border: none
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    width: 42px
    height: 42px
    cursor: pointer
    transition: background 0.3s
    &:hover
        background: rgba(0, 0, 0, 0.1)

.iconLeft
    margin-right: 2px

.iconRight
    margin-left: 2px

.prevButton
    left: 10px

.nextButton
    right: 10px

.dots
    position: absolute
    bottom: 20px
    left: 50%
    transform: translateX(-50%)
    display: flex
    gap: 8px
    z-index: 2

.dot
    width: 8px
    height: 8px
    border-radius: 50%
    background: rgba(255, 255, 255, 0.5)
    border: none
    cursor: pointer
    padding: 0
    &.activeDot
        background: white
