@use 'common'
@use 'sass:math'

=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.wrapper
	font-size: inherit
	display: inline-flex
	vertical-align: middle
	position: relative
	z-index: 0

	&.is_people
		.image
			+normalizeSize(131, 73)

	&.is_robot
		.image
			+normalizeSize(73, 73)
