@use 'common'

.wrapper
	border-radius: common.$radius-medium
	padding: 2rem
	margin: 2rem 0
	gap: 2rem
	color: common.$color-grey-5
	display: grid
	grid-template-columns: 1fr 1fr

.secondaryWrapper
	border: 1px solid var(--tip-box-color)
