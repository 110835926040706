@use 'common'

$inputHeight: 57px
$selectWidth: 150px

.input
	height: $inputHeight
	border: 0
	background-color: common.$color-grey-1
	border-radius: common.$radius-small
	padding: 1rem
	font-size: 1.1rem
	padding-right: 2.4rem
	min-width: $selectWidth
	width: 100%
	color: common.$color-grey-5
	-webkit-appearance: none
	-moz-appearance: none
	::-ms-expand
		display: none

.icon
	color: common.$color-violet-4
	position: absolute
	right: 0.5rem
	top: 1.5rem
	transform: rotate(90deg)
	width: 18px
	height: 10px
