@use 'common'

.wrapper
	min-height: 60vh

.heading
	font-size: 1.9rem
	text-align: center
	@media (min-width: common.$break48)
		font-size: 2.2rem
