@use 'common'

.wrapper
	padding: 0.7rem 3rem 0.7rem 2rem
	margin: 1rem 0
	gap: 1.9rem
	display: flex
	align-items: center
	color: common.$color-grey-5
	&:hover
		.icon
			transform: translateX(0.2rem)

.borderColor
	border-left: 2px solid var(--tip-box-color)

.link
	transition: filter 0.2s ease-in-out
	&:hover
		filter: brightness(0.95)

.icon
	color: var(--tip-box-color)
	transition: 0.2s ease-in-out transform
