@use 'common'

$searchWidth: 430px
$emailWidth: 290px
$emailWideWidth: 420px
$inputHeight: 57px

.input
	height: $inputHeight
	border: 0
	color: common.$color-grey-6
	border-radius: common.$radius-small
	padding: 1rem
	padding-left: 1.7rem
	font-size: 1.1rem
	width: 100%

	&.is_backgroundColor_white
		background-color: common.$color-white

	&.is_backgroundColor_default
		background-color: common.$color-grey-1

	&Search
		position: relative
		max-width: $searchWidth
		width: $searchWidth
		color: common.$color-grey-5
		padding-left: 2.7rem
	@media (min-width: common.$break48)
		width: $emailWidth
	&FormLocated
		background-color: transparent
		border-bottom: 1px solid common.$color-grey-2
		border-radius: 0
		transition: 0.2s ease-in border-color
		padding-left: 0.2rem
		padding-bottom: 0
		&:focus
			outline: none
			border-bottom: 1px solid common.$color-violet-3
		@media (min-width: common.$break48)
			max-width: $emailWideWidth
			width: 100%
