@use 'common'

$imageSize: 225px
.wrapper
	&:hover
		.imageWrapper
			filter: brightness(0.9)
		.fileButtonTitle
			&::after
				top: 105%

.link
	color: common.$color-black
	&:hover
		color: common.$color-grey-5

.imageWrapper
	position: relative
	overflow: hidden
	width: 100%
	height: $imageSize
	transition: filter 0.2s ease-in
	border-radius: common.$radius-medium
	.image
		height: auto
		object-fit: cover
	@media (min-width: common.$break64)
		width: $imageSize

.file
	&Title
		margin-top: 0.7rem
		font-family: common.$albra-semi-bold-font
		font-size: 1.2rem
		line-height: 1.15
		margin-bottom: 0.3rem
		@media (min-width: common.$break64)
			font-size: 1.3rem
			max-width: 90%

	&Content
		color: common.$color-violet-3
		display: flex
		align-items: center
		gap: 0.5rem
	&Button
		border: 0
		background-color: transparent
		color: common.$color-violet-4
		padding: 0
		font-size: 1.1rem
		display: flex
		&Title
			position: relative
			&::after
				content: ''
				display: block
				width: 100%
				height: 1px
				transition: top 0.2s ease-in
				position: absolute
				top: 100%
				left: 0
				background-color: common.$color-violet-4

	&Icon
		width: 16px
		height: 20px
		margin-right: 0.3rem
	&Type
		text-transform: uppercase
		font-size: 1rem
	&Size
		font-size: 1rem
