@use 'common'

$inputHeight: 57px
$searchWidth: 430px
$selectWidth: 150px
.wrapper
	//

.input
	height: $inputHeight
	border: 0
	background-color: common.$color-grey-1
	border-radius: common.$radius-small
	padding: 1rem
	font-size: 1.1rem
	&Search
		position: relative
		max-width: $searchWidth
		width: 100%
		color: common.$color-grey-5
		padding-left: 3rem
	&Select

		max-width: common.$tileWidth
		width: 100%
		color: common.$color-grey-5
		-webkit-appearance: none
		-moz-appearance: none
		::-ms-expand
			display: none

.inputGroup
	position: relative
	width: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row

.icon
	color: common.$color-violet-4
	position: absolute
	right: 0.5rem
	top: 1.5rem
	transform: rotate(90deg)
	width: 18px
	height: 10px

.selectWrapper
	height: $inputHeight
	width: 100%
	position: relative
	margin-top: 1rem
	max-width: $searchWidth
	min-width: common.$tileWidth
	@media (min-width: common.$break48)
		position: absolute
		margin-top: 0
		max-width: $selectWidth
		top: 0
		right: 0

.searchIcon
	width: 26px
	height: 2
	position: absolute
	top: calc(50% - 13px)
	left: 0.8rem

.searchWrapper
	height: $inputHeight
	position: relative
	max-width: $searchWidth
	display: flex
	width: 100%
	@media (min-width: common.$break48)
		width: auto
		display: block

.loaderWrapper
	min-height: 40vh
	display: flex
	justify-content: center
	align-items: center

.buttonWrapper
	text-align: center
	margin-top: 3rem
	@media (min-width: common.$break48)
		margin-top: 5rem
