@use 'common'

$photoSize: 112px
$photoHeightMobile: 150px
.wrapper
	display: grid
	grid-template-columns: 1fr
	gap: 1.5rem
	@media (min-width: common.$break37)
		display: grid
		grid-template-columns: 0.75fr 2fr
		max-width: 31.25rem

	&.is_mobileLayout_vertical
		//

	&.is_mobileLayout_horizontal
		grid-template-columns: auto 2fr

		.imageWrapper
			aspect-ratio: 1 / 1
			width: $photoSize
			height: auto

.imageWrapper
	transition: filter 0.3s ease-in-out
	border-radius: common.$radius-medium
	overflow: hidden
	position: relative
	height: $photoHeightMobile
	@media (min-width: common.$break37)
		width: $photoSize
		height: $photoSize

.image
	object-fit: cover

.contentTitle
	font-size: 1.2rem
	margin-bottom: 0.5rem
	font-family: common.$inter-font
	@media (min-width: common.$break37)

.info
	color: common.$color-grey-4
	font-size: 0.9rem
	&Wrapper
		display: flex
		gap: 1rem
		@media (min-width: common.$break48)
			gap: 1.8rem

.link
	&:hover
		.imageWrapper
			filter: brightness(0.8)
