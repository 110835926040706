@use 'common'
@use 'sass:color'

.wrapper

	h1
		font-size: 4rem

	h2
		font-size: 2.25rem

	h3
		font-size: 1.625rem

	h4
		font-size: 1.325rem

	h5
		font-size: 1.25rem

	em
		font-style: normal
		color: common.$color-primary

	[data-contember-align="center"]
		text-align: center

	[data-contember-align="end"]
		text-align: end
