@use 'common'

$menuwidth: 270px
$linkWidth: 300px
.wrapper
	margin-bottom: 2rem
	@media (min-width: common.$break48)
		max-width: $menuwidth

.title
	font-size: 1.9rem
	&Event
		cursor: pointer
	@media (min-width: common.$break48)
		font-size: 2.2rem

.link
	text-decoration: none
	color: common.$color-grey-6
	transition: 0.1s ease-in color
	&Wrapper
		padding: 10px 4px 12px 12px
		border-radius: common.$radius-extra-small
		transition: 0.1s ease-in all
		margin-bottom: 0.2rem
		&:hover
			background: rgba(233, 224, 255, 0.5)
			color: common.$color-grey-6

		&Active
			background: common.$color-violet-1
			font-weight: 600
			pointer-events: none

	&Active
		color: common.$color-violet-4
		pointer-events: none

.links
	padding-left: 0
	max-width: $linkWidth

.button
	padding: 10px 4px 12px 12px
	border-radius: common.$radius-extra-small
	margin-bottom: 0.2rem
	transition: 0.1s ease-in all
	display: block
	text-align: left
	width: 100%
	color: common.$color-black
	background-color: transparent
	font-size: 1.1rem
	&:hover
		background: rgba(233, 224, 255, 0.5)
		color: common.$color-grey-6
	&Active
		color: common.$color-violet-4
		pointer-events: none
		font-weight: 600
		background: rgba(233, 224, 255, 0.5)

	&::first-letter
		text-transform: uppercase
	&:focus
		outline: none

.buttonWrapper
	margin-top: 2.5rem