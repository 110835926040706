@use 'common'

.wrapper
	background-color: common.$color-grey-1
	border-radius: common.$radius-medium
	padding: 1.5rem 2rem
	display: grid
	margin-bottom: 2rem
	align-items: flex-end
	gap: 1rem
	@media (min-width: common.$break30)
		grid-template-columns: 2fr 0.5fr

.title
	font-family: common.$albra-semi-bold-font
	font-size: 1.3rem
	color: common.$color-black
	font-weight: 600

.perex
	font-size: 1.1rem
	color: common.$color-black
	margin-bottom: 1rem

.link
	margin-top: 0.5rem
	display: block
	font-size: 1.1rem

.iconWrapper
	width: 60px
	text-align: right
	margin-left: auto
	margin-right: 1rem
	display: none
	@media (min-width: common.$break30)
		display: block

.form
	&In
		border: 0
	&Title
		font-size: 1.4rem
		font-weight: 600
		color: common.$color-black
		margin: 1rem 0

.buttonWrapper
	margin: 2rem 0 1rem
	&:last-of-type
		margin: 0

.button
	font-family: common.$inter-font
	color: common.$color-violet-4
	background-color: transparent
	padding: 0
	margin-top: 0.5rem
	transition: 0.2s ease-in-out color
	display: block
	font-size: 1.1rem
	height: 24px
	&:focus
		outline: none
	&:hover
		color: common.$color-violet-3
