@use 'common'

.wrapper
	&:hover
		.imageWrapper
			filter: brightness(.8)
	&Front
		//

.imageWrapper
	box-shadow: 1px 1px 4px common.$color-grey-2
	transition: filter 0.3s ease-in-out
	border-radius: common.$radius-medium
	position: relative
	aspect-ratio: 27/18
	overflow: hidden
	margin-bottom: 0.8rem
	&:after
		content: ''
		display: block
		padding-top: 56.25%
	&Front
		max-width: 405px

.image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	object-fit: cover

.date
	font-size: 0.8rem
	color: common.$color-grey-5
	margin-bottom: 0.5rem

.title
	font-size: 1.2rem
	color: common.$color-black
	margin-bottom: unset
	@media (min-width: common.$break48)
		font-size: 1.4rem
