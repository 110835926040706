@use 'common'

$boxSize: 95px

.wrapper
	padding-inline: 2rem
	margin: 2rem 0
	gap: 2rem

.borderColor
	border-left: 1px solid var(--subscribe-box-color)

.title
	font-family: common.$albra-semi-bold-font
	color: common.$color-black
	margin-bottom: 1rem
	font-size: 1.3rem
