@use 'common'

.wrapper
	padding: 0.7rem 2rem 0.3rem
	margin: 2rem 0
	color: common.$color-grey-5
	gap: 0.5rem
	display: grid
	@media (min-width: common.$break48)
		gap: 1.5rem
		grid-template-columns: 2fr 1fr

	&NoImage
		@media (min-width: common.$break48)
			grid-template-columns: 1fr

.borderColor
	border-left: 1px solid var(--article-promo-box-color)

.link
	transition: filter 0.2s ease-in-out
	&:hover
		.imageWrapper
			filter: brightness(0.95)
	.text
		transition: 0.2s ease-in-out color
		&:hover
			color: common.$color-primary

.imageWrapper
	border-radius: common.$radius-small
	max-width: 200px
	height: 100%
	min-height: 100px
	position: relative
	overflow: hidden
	transition: filter 0.2s ease-in-out

.image
	object-fit: cover

.content
	//

.text
	font-size: 1.15rem
	font-weight: 600
	color: common.$color-black
	line-height: 1.2
	margin-bottom: 0.7rem
	@media (min-width: common.$break48)
		font-size: 1.3rem

.info
	font-size: 1rem

.readingTime
	padding-left: 1rem
