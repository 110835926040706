@use 'common'

$verticalGap: 1.25rem

.wrapper
	background-color: common.$color-grey-1
	padding: 1.25rem
	border-radius: 1.25rem

	@media (min-width: common.$break48)
		padding: 2.5rem 3.75rem

.main
	display: flex
	flex-direction: column
	justify-content: space-between
	align-items: center
	gap: 1.5rem

	@media (min-width: common.$break48)
		flex-direction: row
		gap: 2.5rem

.column
	display: grid
	gap: $verticalGap
	width: 100%

	&:first-child
		@media (min-width: common.$break48)
			max-width: 28.75rem

	&:last-child
		@media (min-width: common.$break48)
			display: grid
			justify-content: center
			max-width: 38.125rem

.image
	width: 100%
	height: auto

	&Wrapper
		display: flex
		position: relative
		max-width: 450px
		width: 100%
		max-height: 280px
		overflow: hidden
		border-radius: common.$radius-medium

.title
	margin-block: unset

.description
	margin-block: unset

.form
	display: flex
	align-items: center
	flex-direction: column
	gap: $verticalGap
	width: 100%

	@media not all and (min-width: common.$break62)
		justify-content: center

	@media (min-width: common.$break48)
		flex-direction: row
		width: auto

.inputWrapper
	position: relative
	width: 100%
	@media (min-width: common.$break48)
		width: auto

.inputSubscribed
	font-size: 0.9rem
	position: absolute
	top: calc(100% + 5px)
	left: 0
