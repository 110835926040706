@use 'common'

$-tableBorderColor: #EBEBEB

.wrapper
	--ContentRenderer-gap: var(--content-smallSpacing)
	--ContentRenderer-wysiwygGap-small: 1.5rem

	@media (min-width: common.$break48)
		--ContentRenderer-wysiwygGap-small: 2rem

	&:not(.is_disabledBottomSpacing)
		padding-bottom: var(--ContentRenderer-gap)
	h1,
	h2,
	h3,
	h4
		line-height: 1.15
		margin-top: var(--ContentRenderer-wysiwygGap-small)
		margin-bottom: var(--ContentRenderer-wysiwygGap-small)

	h5
		margin-bottom: 1rem
		margin-bottom: 1rem

	h4
		margin-bottom: 1rem
		font-size: 1.4rem
		@media (min-width: common.$break48)
			font-size: 1.7rem
	h3
		font-size: 1.7rem
		margin-bottom: 1rem
		@media (min-width: common.$break48)
			font-size: 2rem
	h2
		font-size: 2rem
		margin-bottom: 1.2rem
		@media (min-width: common.$break48)
			font-size: 2.4rem

	.tipBoxText a[data-contember-type="anchor"]
		color: common.$color-white
		transition: color 0.2s ease-out
		text-decoration: underline

	.tipBoxText
		a[data-contember-type="anchor"]
			color: common.$color-white
			transition: color 0.2s ease-out

			text-decoration: underline
		p
			a[data-contember-type="anchor"]
				color: common.$color-white
				transition: color 0.2s ease-out
				text-decoration: underline

.section
	&:not(.is_wysiwyg) + &,
	& + &:not(.is_wysiwyg)
		margin-top: var(--ContentRenderer-gap)

	iframe
		max-width: 100%

	$referencesWithSmallSpacingBeforeWysiwyg: ".is_reference_image, .is_reference_file"
	$referencesWithSmallSpacingAfterWysiwyg: ".is_reference_image, .is_reference_file, .is_reference_buttonLinks, .is_reference_gallery, .is_reference_topLink"
	$referencesWithSmallSpacingAfterFile: ".is_reference_file"
	$referencesWithSmallSpacingAfterButtonLinks: ".is_reference_image"

	&.is_reference_file + &:is(#{$referencesWithSmallSpacingAfterFile})
		margin-top: var(--content-smallSpacing)

	&.is_reference_buttonLinks + &:is(#{$referencesWithSmallSpacingAfterButtonLinks})
		margin-top: var(--content-smallSpacing)

	&.is_wysiwyg + &:is(#{$referencesWithSmallSpacingAfterWysiwyg})
		margin-top: var(--content-smallSpacing)

	&:is(#{$referencesWithSmallSpacingBeforeWysiwyg}) + &.is_wysiwyg
		margin-top: var(--content-smallSpacing)

	$referencesWithSmallGapBeforeHeading: ".is_paragraph, .is_unorderedList"

	&:is(#{$referencesWithSmallGapBeforeHeading}) + &.is_heading
		margin-top: var(--content-gap-small)

	&.section + &.is_reference_buttonLinks
		margin-top: var(--ContentRenderer-wysiwygGap-small)

	[data-contember-type="table"]
		--ContentRenderer-tableCell-inlinePadding: .5rem

		border-spacing: 0
		border-collapse: collapse
		margin: -0.9375rem calc(-1 * var(--ContentRenderer-tableCell-inlinePadding))

		@media (min-width: common.$break30)
			--ContentRenderer-tableCell-inlinePadding: .625rem

	[data-contember-type="tableRow"]
		//

	[data-contember-type="tableCell"]
			padding: .9375rem var(--ContentRenderer-tableCell-inlinePadding)
			vertical-align: top
			@media (min-width: common.$break30)
				min-width: calc(11.5625rem + 2 * var(--ContentRenderer-tableCell-inlinePadding))
				padding-inline: .625rem

	[data-contember-type="scrollTarget"]
				scroll-margin-top: 9.375rem

	[data-contember-type="unorderedList"]
				padding-left: 1.25rem
				list-style-type: disc

	h1[data-contember-type="heading"],
	h2[data-contember-type="heading"],
	h3[data-contember-type="heading"],
	h4[data-contember-type="heading"]
		margin-top: var(--ContentRenderer-wysiwygGap-small)
		margin-bottom: var(--ContentRenderer-wysiwygGap-small)

	h5[data-contember-type="heading"]
		margin-bottom: 1rem

	h2
		font-size: 2rem
		@media (min-width: common.$break48)
			font-size: 2.5rem

.notImplemented
	border: 0.0625rem solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize

.is_reference_gallery
	display: grid

.question
	color: common.$color-black
	font-weight: 600
	font-size: 1.1rem
	margin-bottom: 0.5rem
	@media (min-width: common.$break48)
		font-size: 1.3rem

.line
	width: var(--line-width)
	background-color: var(--line-color)
	height: 1px
	margin: 1.5rem 0

.sourceText
	font-size: 0.9rem
	margin-top: 0.5rem
	margin-left: 2px
	color: common.$color-grey-4
